<div [formGroup]="companyFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <div fxFlex="100">

        <ng-container *ngIf="headline?.length">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <ng-container *ngIf="!slimmedView">
                    <h2 [innerHTML]="headline"></h2>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="loadingCompanies; else formContent">
            <div style="display: flex; justify-content: center; align-items: center;">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </ng-container>

        <ng-template #formContent>

            <ng-container *ngIf="!companies?.length">
                <u2b-message>
                    <header>Keine Organisation gefunden</header>
                    <p>Es konnten keine Organisationen im System gefunden werden.</p>
                </u2b-message>
            </ng-container>

            <ng-container *ngIf="companies?.length">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                        <mat-icon matPrefix>search</mat-icon>
                        <mat-label>{{ inputLabel }}</mat-label>
                        <input matInput
                               type="text"
                               formControlName="company"
                               [matAutocomplete]="companySelector">
                        <div matSuffix>
                            <ng-container *ngIf="editable && company?.id">
                                <button
                                        mat-icon-button
                                        type="button"
                                        color="primary"
                                        matTooltip="Organisation bearbeiten"
                                        (click)="openEditDialog($event)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!company?.id && canCreate">
                                <button
                                        mat-icon-button
                                        type="button"
                                        color="primary"
                                        matTooltip="Organisation erstellen"
                                        [disabled]="companyFormGroup.get('company').disabled"
                                        (click)="openEditDialog($event)">
                                    <mat-icon>add_circle_outline</mat-icon>
                                </button>
                            </ng-container>
                            <button
                                    mat-icon-button
                                    type="button"
                                    color="warn"
                                    matTooltip="Ausgewählte Organisation entfernen"
                                    [disabled]="!company?.id || companyFormGroup.get('company').disabled"
                                    (click)="onClickRemoveCompany()">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                        <mat-error>{{ companyFormGroup.get('company').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>

                    <mat-autocomplete
                            #companySelector="matAutocomplete"
                            [displayWith]="displayCompanyWith">
                        <ng-container *ngFor="let company of filteredCompanies$ | async">
                            <mat-option [value]="company">
                                <bcm-company-link [disableLink]="true"
                                                  [company]="company"
                                                  [suffix]="company.identNumber ? (' (' + company.identNumber + ')') : null"></bcm-company-link>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </ng-container>

            <ng-container *ngIf="company?.id">
                <ng-container *ngIf="company.activeTenantRelationAssignments.length && !slimmedView">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <strong>Aktive Beziehungen:</strong>
                        <bcm-tenant-relation-colored-label
                                [tenantRelations]="company?.activeTenantRelationAssignments"></bcm-tenant-relation-colored-label>
                    </div>
                </ng-container>
            </ng-container>

        </ng-template>

    </div>

</div>
