import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormWidgetCompanyComponent } from '@modules/bcm/@work-flows/@form-widgets/company/form-widget-company.component';
import { HtmlLinksModule } from '@modules/bcm/@shared/components/html-links/html-links.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {
    TenantRelationColoredLabelModule
} from '@modules/bcm/@shared/components/tenant-relation-colored-label/tenant-relation-colored-label.module';
import { CompanyEditorDialogComponent } from './company-editor-dialog/company-editor-dialog.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { TranslationModule } from '@core/translation/translation.module';

@NgModule({
    declarations: [FormWidgetCompanyComponent, CompanyEditorDialogComponent],
    imports: [
        FuseSharedModule,
        SharedModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        HtmlLinksModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatTooltipModule,
        TenantRelationColoredLabelModule,
        CdkDrag,
        CdkDragHandle,
        TranslationModule,
    ],
    exports: [FormWidgetCompanyComponent]
})
export class FormWidgetCompanyModule {
    
}
