import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Product } from '@shared/models/product';
import { BcmDynamicPrice, BcmDynamicPriceContext } from '@shared/models/bcm-dynamic-price';
import { Boat } from '@shared/models/boat';
import { Berth } from '@shared/models/berth';
import { TenantRelation } from '@shared/models/tenant-relation';
import { Season } from '@shared/models/seasons';
import { Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { BcmProductDynamicPriceMap } from '@shared/models/bcm-price-rule-map';
import { WinterStorage } from '@shared/models/winter-storage';
import { SeasonsService } from '@modules/bcm/seasons/seasons.service';
import { Pier } from '@shared/models/pier';

@Component({
    selector: 'product-dynamic-price',
    templateUrl: './product-dynamic-price.component.html',
    styleUrls: ['./product-dynamic-price.component.scss']
})
export class ProductDynamicPriceComponent implements OnInit, OnDestroy, OnChanges {

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @Input() parentFormGroup: UntypedFormGroup;

    @Input() dynamicPrice: BcmDynamicPrice;

    @Input() dynamicPriceContext: BcmDynamicPriceContext;

    @Input() product: Product;

    @Input()
    useDynamicPrice: boolean;

    @Output()
    dynamicPriceChanged: EventEmitter<BcmDynamicPrice> = new EventEmitter<BcmDynamicPrice>();

    @Output()
    dynamicPriceErrorChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    useDynamicPriceChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    dynamicPriceOld: BcmDynamicPrice;

    boats: Boat[] = [];
    berths: Berth[] = [];
    tenantRelations: TenantRelation[] = [];
    seasons: Season[] = [];
    winterStorages: WinterStorage[] = [];
    piers: Pier[] = [];

    boat: Boat;
    berth: Berth;
    tenantRelation: TenantRelation;
    season: Season;
    winterStorage: WinterStorage;
    pier: Pier;

    requirements: string[] = [];

    formGroup: UntypedFormGroup;

    init = false;

    parentTitle: string;

    seasonChangedManually: boolean;

    initIndexChanged: boolean;
    initTitleChanged: boolean;
    initPriceChanged: boolean;

    constructor(private _formBuilder: FormBuilder,
                private _changeDetectorRef: ChangeDetectorRef,
                private _seasonsService: SeasonsService,
                private _productService: ProductService) {
    }

    ngOnInit(): void {
        this._createForm();

        this._processContext();

        try { // TODO find bug .. (thilo: @pascal: which bug?)
            this.requirements = this.product.priceRuleMap?.getRequirements();
        } catch (e) {
            this.product.priceRuleMap = new BcmProductDynamicPriceMap({...this.product?.priceRuleMap});
            this.requirements = this.product?.priceRuleMap?.getRequirements();
        }

        if (this.dynamicPrice?.id >= 0 || this.dynamicPrice?.ruleIndex >= 0) {
            this.dynamicPriceOld = {...this.dynamicPrice};
            this.dynamicPrice = new BcmDynamicPrice({id: this.dynamicPrice.id});

            // ExpressionChangedAfterItHasBeenCheckedError fix
            Promise.resolve(null).then(async () => {
                await this._validateRules();
            });
        }

        this.formGroup
            .valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(async values => {

                this.boat = values.boatForm?.boat;
                this.berth = values.berthForm?.berth;
                this.tenantRelation = values.tenantRelationForm?.tenantRelation;
                this.season = values.seasonForm?.season;
                this.winterStorage = values.winterStorageForm?.winterStorage;
                this.pier = values.pierForm?.pier;

                this._checkRequirements();

                this.useDynamicPriceChanged.next(values.useDynamicPrice);

                if (values.useDynamicPrice) {
                    await this._validateRules();

                    if (!this.useDynamicPrice) {
                        this._setDefaultValues();
                    }
                }

                this._changeDetectorRef.detectChanges();

            });

        this._checkRequirements();
        this._changeDetectorRef.detectChanges();

        if (!this.dynamicPrice?.id) {
            this.init = true;
        }

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
        this.parentFormGroup.removeControl('dynamicPriceFormGroup');
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'product': {
                        if (this.useDynamicPrice) {
                            setTimeout(() => {
                                // ExpressionChangedAfterItHasBeenCheckedError fix
                                Promise.resolve(null).then(async () => {
                                    await this._validateRules();
                                });
                                setTimeout(() => this.init = true, 100);
                            }, 250);
                        }
                        break;
                    }
                    case 'dynamicPriceContext': {

                        const fromDateChanged = changes['dynamicPriceContext'].previousValue?.fromDate !== changes['dynamicPriceContext'].currentValue?.fromDate;
                        const toDateChanged = changes['dynamicPriceContext'].previousValue?.toDate !== changes['dynamicPriceContext'].currentValue?.toDate;

                        if ((!this.seasonChangedManually || !this.formGroup.get('seasonForm')?.get('season')?.value?.id) && (fromDateChanged || toDateChanged)) {
                            this.processContextSeason();
                            this.seasonChangedManually = false;
                        }

                        if (this.useDynamicPrice) {
                            setTimeout(() => {
                                // ExpressionChangedAfterItHasBeenCheckedError fix
                                Promise.resolve(null).then(async () => {
                                    await this._validateRules();
                                });
                                setTimeout(() => this.init = true, 100);
                            }, 250);
                        }

                        break;
                    }
                }
            }
        }
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            useDynamicPrice: [this.useDynamicPrice],
            dynamicPrice: []
        });

        this.formGroup.get('useDynamicPrice').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                this.useDynamicPrice = value;
                if (!value) {
                    this.formGroup.get('dynamicPrice').setValue(null);

                    if (this.parentTitle && this.parentFormGroup?.get('title')) {
                        this.parentFormGroup.get('title').setValue(this.parentTitle);
                    }
                } else {
                    this.formGroup.get('dynamicPrice').setValue(this.dynamicPrice);
                }
            });

        this.parentFormGroup.addControl('dynamicPriceFormGroup', this.formGroup);

        this.parentTitle = this.parentFormGroup.get('title').value;

        this.parentFormGroup.get('quantity')?.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async () => {
                if (this.formGroup.get('useDynamicPrice').value) {
                    await this._validateRules();
                }
            });
    }

    private _checkRequirements(): void {
        const values = this.formGroup?.getRawValue();

        if (!values) {
            return;
        }

        this.dynamicPriceErrorChanged.next(false);

        if (values.useDynamicPrice && this.requirements?.length > 0) {
            if (this.requirements.includes('boat') && (!values.boatForm?.boat?.id && !this.boat?.id)) {
                this.dynamicPriceErrorChanged.next(true);
            } else if (this.requirements.includes('berth') && (!values.berthForm?.berth?.id && !this.berth?.id)) {
                this.dynamicPriceErrorChanged.next(true);
            } else if (this.requirements.includes('tenantRelation') && (!values.tenantRelationForm?.tenantRelation?.id && !this.tenantRelation?.id)) {
                this.dynamicPriceErrorChanged.next(true);
            } else if (this.requirements.includes('season') && (!values.seasonForm?.season?.id && !this.season?.id)) {
                this.dynamicPriceErrorChanged.next(true);
            } else if (this.requirements.includes('winterStorage') && (!values.winterStorageForm?.winterStorage?.id && !this.winterStorage?.id)) {
                this.dynamicPriceErrorChanged.next(true);
            } else if (this.requirements.includes('pier') && (!values.pierForm?.pier?.id && !this.pier?.id)) {
                this.dynamicPriceErrorChanged.next(true);
            }
        }

    }

    private _processContext(): void {
        this.boats = (this.dynamicPriceContext?.person?.boats || this.dynamicPriceContext?.company?.boats) || [];

        if (this.dynamicPriceContext?.preselection?.boat?.id &&
            (this.boats.length === 0 || this.boats.findIndex(b => b.id === this.dynamicPriceContext?.preselection.boat.id) === -1)) {
            this.boats.push(this.dynamicPriceContext?.preselection?.boat);
        }

        this.berths = this.boats.flatMap(boat => boat.berthAssignment?.berth).filter(value => value?.id) || [];

        if (this.dynamicPriceContext?.preselection?.berth?.id &&
            (this.berths.length === 0 || this.berths.findIndex(b => b.id === this.dynamicPriceContext?.preselection.berth.id) === -1)) {
            this.berths.push(this.dynamicPriceContext?.preselection?.berth);
        }

        this.tenantRelations = (
            this.dynamicPriceContext?.person?.activeTenantRelationAssignments
            || this.dynamicPriceContext?.company?.activeTenantRelationAssignments
            || []
        ).map(tenantRelationAssignment => tenantRelationAssignment.tenantRelation) || [];

        if (this.dynamicPriceContext?.preselection?.tenantRelation?.id &&
            (this.tenantRelations.length === 0 || this.tenantRelations.findIndex(t => t.id === this.dynamicPriceContext?.preselection.tenantRelation.id) === -1)) {
            this.tenantRelations.push(this.dynamicPriceContext?.preselection?.tenantRelation);
        }

        this.winterStorages = this.dynamicPriceContext?.preselection?.winterStorage?.id ? [this.dynamicPriceContext?.preselection?.winterStorage] : [];

        if (this.dynamicPriceContext?.preselection?.winterStorage?.id &&
            (this.winterStorages.length === 0 || this.winterStorages.findIndex(w => w.id === this.dynamicPriceContext?.preselection.winterStorage.id) === -1)) {
            this.winterStorages.push(this.dynamicPriceContext?.preselection?.winterStorage);
        }

        this.piers = this.dynamicPriceContext?.preselection?.pier?.id ? [this.dynamicPriceContext?.preselection?.pier] : [];

        if (this.dynamicPriceContext?.preselection?.pier?.id &&
            (this.piers.length === 0 || this.piers.findIndex(p => p.id === this.dynamicPriceContext?.preselection.pier.id) === -1)) {
            this.piers.push(this.dynamicPriceContext?.preselection?.pier);
        }

        if (this.dynamicPrice?.ruleIndex === -1 || !this.dynamicPrice) {
            const preselectedBoat = this.boats.find(b => b.id === this.dynamicPriceContext?.preselection?.boat?.id);
            this.boat = preselectedBoat ? preselectedBoat : ((this.boats.length === 1) ? this.boats[0] : null);

            const preselectedBerth = this.berths.find(b => b.id === this.dynamicPriceContext?.preselection?.berth?.id);
            this.berth = preselectedBerth ? preselectedBerth : ((this.berths.length === 1) ? this.berths[0] : null);

            const preselectedTenantRelation = this.tenantRelations.find(b => b.id === this.dynamicPriceContext?.preselection?.tenantRelation?.id);
            this.tenantRelation = preselectedTenantRelation
                ? preselectedTenantRelation
                : ((this.tenantRelations.length === 1) ? this.tenantRelations[0] : null);

            this.processContextSeason();

            const preselectedWinterStorage = this.winterStorages.find(b => b.id === this.dynamicPriceContext?.preselection?.winterStorage?.id);
            this.winterStorage = preselectedWinterStorage ? preselectedWinterStorage : ((this.winterStorages.length === 1) ? this.winterStorages[0] : null);

            const preselectedPier = this.piers.find(p => p.id === this.dynamicPriceContext?.preselection?.pier?.id);
            this.pier = preselectedPier ? preselectedPier : ((this.piers.length === 1) ? this.piers[0] : null);
        } else {
            const {boat, berth, tenantRelation, season, winterStorage, pier} = this.dynamicPrice;

            this.boat = boat;
            this.berth = berth;
            this.tenantRelation = tenantRelation;
            this.season = season;
            this.winterStorage = winterStorage;
            this.pier = pier;
        }

    }

    private processContextSeason() {
        if (this.seasons?.length > 0) {
            this.season = this._getPreselectedSeason(this.seasons, this.dynamicPriceContext);
        } else if (this._seasonsService.seasons?.length > 0) {
            this.seasons = this._seasonsService.seasons;
            this.season = this._getPreselectedSeason(this.seasons, this.dynamicPriceContext);
        } else {
            this._seasonsService.getSeasons()
                .pipe(take(1))
                .subscribe(seasons => {
                    this.seasons = seasons;
                    this.season = this._getPreselectedSeason(this.seasons, this.dynamicPriceContext);
                });
        }
    }

    private _getPreselectedSeason(seasons: Season[], dynamicPriceContext: BcmDynamicPriceContext): Season {
        let preselectedSeason = seasons.find(s => s.id === dynamicPriceContext?.preselection?.season?.id);

        if (!preselectedSeason) {
            preselectedSeason = seasons.find(s => {

                const currentDate = new Date();

                let tmpStartDate = new Date(currentDate.getFullYear(), s.startDate.getMonth(), s.startDate.getDay());
                const tmpEndDate = new Date(currentDate.getFullYear(), s.endDate.getMonth(), s.endDate.getDay());

                if (tmpStartDate.getTime() > tmpEndDate.getTime()) {
                    tmpStartDate = new Date(tmpStartDate.getFullYear() - 1, tmpStartDate.getMonth(), tmpStartDate.getDay());
                }

                const fromDateIsAfterSeasonStart = !!tmpStartDate && !!dynamicPriceContext?.fromDate && tmpStartDate?.getTime() < dynamicPriceContext?.fromDate?.getTime();
                const toDateIsBeforeSeasonEnd = !!tmpEndDate && !!dynamicPriceContext?.toDate && tmpEndDate?.getTime() > dynamicPriceContext?.fromDate?.getTime();

                return fromDateIsAfterSeasonStart && toDateIsBeforeSeasonEnd;
            });
        }

        return preselectedSeason ? preselectedSeason : ((seasons.length === 1) ? seasons[0] : null);
    }

    private _setDefaultValues(): void {
        this.boat = ((this.boats.length === 1) ? this.boats[0] : null);
        this.berth = ((this.berths.length === 1) ? this.berths[0] : null);
        this.tenantRelation = ((this.tenantRelations.length === 1) ? this.tenantRelations[0] : null);
        this.season = ((this.seasons.length === 1) ? this.seasons[0] : null);
        this.winterStorage = ((this.winterStorages.length === 1) ? this.winterStorages[0] : null);
        this.pier = ((this.piers.length === 1) ? this.piers[0] : null);
    }

    private async _validateRules(): Promise<void> {
        if (!this.product) {
            // no product
            return;
        } else if (!(this.product.priceRuleMap?.ruleSets?.length > 0 || this.product.priceRuleMap?.calculationSets?.length > 0)) {
            // no rules
            return;
        }

        const boat = this.boat;
        const berth = this.berth;
        const tenantRelation = this.tenantRelation;
        const season = this.season;
        const winterStorage = this.winterStorage;
        const pier = this.pier;

        const quantity = parseInt(this.parentFormGroup.get('quantity')?.value || 0, 10);

        const parameters = {boat, berth, tenantRelation, season, winterStorage, pier};

        this._productService
            .evaluatePriceRule(this.product, parameters, quantity, {
                width: boat?.width,
                length: boat?.length
            }, this.dynamicPriceContext?.fromDate, this.dynamicPriceContext?.toDate)
            .subscribe(dynamicPrice => {

                this.dynamicPrice = {
                    ...dynamicPrice,
                    id: this.dynamicPriceOld?.id,
                    boat: this.boat,
                    berth: this.berth,
                    tenantRelation: this.tenantRelation,
                    season: this.season,
                    winterStorage: this.winterStorage,
                    pier: this.pier,
                };

                this.formGroup.get('dynamicPrice').setValue(this.dynamicPrice, {emitEvent: false});
                this.parentFormGroup.get('title')?.setValue(this.dynamicPrice.ruleName);
                this.parentFormGroup.get('price')?.setValue(this.dynamicPrice.rulePrice);

                this.dynamicPriceChanged.next(this.dynamicPrice);

                if (this.dynamicPriceOld?.id) {
                    this.initIndexChanged = parseFloat(String(this.dynamicPriceOld?.ruleIndex)) !== parseFloat(String(this.dynamicPrice.ruleIndex));
                    this.initTitleChanged = this.dynamicPriceOld?.ruleName !== this.dynamicPrice.ruleName;
                    this.initPriceChanged = parseFloat(String(this.dynamicPriceOld?.rulePrice)) !== parseFloat(String(this.dynamicPrice.rulePrice));
                }
            });

    }

}
