import { PayableOption } from '@shared/models/payable-option';
import { IProduct, Product } from '@shared/models/product';
import { ProductSubscription, ProductSubscriptionDto } from '@shared/models/product-subscription';
import { Contract, IContract } from '@shared/models/contract';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { getReadableDateRange } from '@shared/functions/get-readable-date-range';
import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { TenantRelation, TenantRelationRaw } from '@shared/models/tenant-relation';
import { BcmCostCenter, BcmCostCenterDto } from '@shared/models/bcm-cost-center';

export interface TenantRelationAssignmentDto {
    id: number;
    vestingPeriodFrom?: string;
    vestingPeriodUntil?: string;
    fromDate?: string;
    toDate?: string;
    periodFromDate?: string;
    periodToDate?: string;
    lastDayOfMonth?: boolean;
    payableOption: PayableOption;
    products?: IProduct[];
    subscriptions?: ProductSubscriptionDto[];
    contracts: IContract[];
    tenantRelation: TenantRelationRaw;
    person?: IPerson;
    company?: ICompany;
    sortIndex?: number;
    costCenter?: BcmCostCenterDto;
    captureTenantRelation?: boolean;
}

export class TenantRelationAssignment {
    id: number;
    vestingPeriodFrom?: Date;
    vestingPeriodUntil?: Date;
    fromDate?: Date;
    toDate?: Date;
    periodFromDate?: Date;
    periodToDate?: Date;
    lastDayOfMonth?: boolean;
    payableOption: PayableOption;
    products?: Product[];
    subscriptions?: ProductSubscription[];
    contracts: Contract[];
    tenantRelation: TenantRelation;
    person?: Person;
    company?: Company;
    sortIndex?: number;
    costCenter?: BcmCostCenter;
    captureTenantRelation?: boolean;

    // helper
    payableOptionName: string;
    rangeTooltip: string;
    upcomingTenantRelation: boolean;
    costCenters?: BcmCostCenter;

    today = new Date();

    constructor(assignment: TenantRelationAssignmentDto) {
        this.updateData(assignment);
    }

    public updateData(assignment: TenantRelationAssignmentDto | TenantRelationAssignment) {
        this.id = assignment.id;
        this.fromDate = tryParseDate(assignment.fromDate);
        this.toDate = tryParseDate(assignment.toDate);
        this.periodFromDate = tryParseDate(assignment.periodFromDate);
        this.periodToDate = tryParseDate(assignment.periodToDate);
        this.vestingPeriodFrom = tryParseDate(assignment.vestingPeriodFrom);
        this.vestingPeriodUntil = tryParseDate(assignment.vestingPeriodUntil);
        this.payableOption = assignment.payableOption;
        this.payableOptionName = assignment.payableOption?.name;
        this.products = (assignment.products || []).map(product => new Product(product as IProduct));
        this.subscriptions = (assignment.subscriptions || []).map(subscription => new ProductSubscription(subscription));
        this.contracts = (assignment.contracts || []).map(contract => new Contract(contract));
        this.tenantRelation = new TenantRelation(assignment.tenantRelation as TenantRelationRaw);
        this.person = assignment.person ? new Person(assignment.person as IPerson) : undefined;
        this.company = assignment.company ? new Company(assignment.company as ICompany) : undefined;
        this.costCenter = assignment.costCenter ? new BcmCostCenter(assignment.costCenter as BcmCostCenterDto) : undefined;
        this.lastDayOfMonth = assignment.lastDayOfMonth;

        this.rangeTooltip = getReadableDateRange(this.fromDate, this.toDate);
        this.upcomingTenantRelation = this.fromDate && this.fromDate > this.today;

        this.captureTenantRelation = assignment.captureTenantRelation;
    }
}
