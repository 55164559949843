<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <form (ngSubmit)="onClickSaveAndClose()" [formGroup]="formGroup" appInvalidControlScroll
          [scrollContainer]="scrollContainer">

        <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">
                Positionen erstellen
                </span>
                <button data-test="B-Dialog schließen" mat-icon-button (click)="onClickClose()"
                        aria-label="Dialog schließen" type="button">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div mat-dialog-content cdkScrollable #scrollContainer>

            <u2b-message type="info">
                <ng-container *ngIf="owner; else noOwner">
                    Eigner: <strong>{{owner.fullName}}</strong>
                </ng-container>
                <ng-template #noOwner>
                    {{('winterStorageAssetNoOwner' | translate : {asset: (winterStorageAssignment.boat ? ('boat' | translate) : 'Asset')})}}
                </ng-template>
            </u2b-message>


            <mat-divider class="special-divider"></mat-divider>

            <div class="mb-24">
                <mat-checkbox formControlName="deviatingInvoiceRecipient">Abweichender Rechnungsempfänger
                </mat-checkbox>
            </div>

            <ng-container *ngIf="formGroup.get('deviatingInvoiceRecipient').value">
                <div>
                    <mat-radio-group class="radio-group-inline" formControlName="invoiceRecipientType">
                        <mat-radio-button [value]="'person'">Person</mat-radio-button>
                        <mat-radio-button [value]="'company'">Organisation</mat-radio-button>
                    </mat-radio-group>
                </div>

                <ng-container *ngIf="formGroup.get('invoiceRecipientType').value === 'person'">
                    <form-widget-person [optionalFields]="true"></form-widget-person>
                </ng-container>

                <ng-container *ngIf="formGroup.get('invoiceRecipientType').value === 'company'">
                    <form-widget-company [optionalFields]="true"></form-widget-company>
                </ng-container>

            </ng-container>

            <mat-divider class="special-divider"></mat-divider>

            <ng-container>
                <form-widget-invoice [parentFormGroup]="formGroup"
                                     [givenStartDate]="winterStorageAssignment?.from"
                                     [givenEndDate]="winterStorageAssignment?.to"
                                     [givenCostCenter]="winterStorageAssignment?.winterStorage?.costCenter"></form-widget-invoice>

                <mat-divider class="special-divider"></mat-divider>
            </ng-container>

            <ng-container *ngIf="subscription">
                <form-widget-subscription-period [parentFormGroup]="formGroup"></form-widget-subscription-period>

                <mat-divider class="special-divider"></mat-divider>
            </ng-container>

            <ng-container
                    *ngIf="(formGroup.dirty && formGroup.invalid) || (formGroup.dirty && formGroup.invalid) || positions.length === 0">
                <u2b-message type="warning" customClass="mt-24">
                    Deine Angaben sind noch nicht vollständig, oder enthalten Fehler, die
                    korrigiert werden müssen.
                </u2b-message>
            </ng-container>

            <ng-container
                    *ngIf="formGroup.dirty && formGroup.valid &&  positions.length > 0">
                <u2b-message type="success" customClass="mt-24">
                    <header>Geschafft!</header>
                    Alle Daten wurden erfasst. Klicke auf "Speichern" um die Daten im System zu speichern.
                </u2b-message>
            </ng-container>
        </div>

        <div mat-dialog-actions class="m-0 p-8 p-sm-16" fxLayout="row" fxLayoutAlign="space-between center">

            <button data-test="B-Abbrechen" mat-flat-button (click)="onClickClose()" type="button">
                Abbrechen
            </button>

            <div>
                <ng-template #saving>
                    <mat-icon>save</mat-icon>
                    <span>Wird gespeichert...</span>
                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                </ng-template>

                <ng-template #notSaving>
                    <mat-icon>save</mat-icon>
                    <span>Speichern</span>
                </ng-template>

                <button type="submit" data-test="B-Speichern" mat-flat-button color="accent"
                        [disabled]="isSaving">
                    <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
                </button>
            </div>

        </div>
    </form>
</div>
