import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Company } from '@shared/models/company';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { isFunction } from '@shared/functions/is-function';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { CompanyApiService } from '@modules/bcm/@shared/services';
import { HttpParams } from '@angular/common/http';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyEditorDialogComponent } from '@bcm-work-flows/@form-widgets/company/company-editor-dialog/company-editor-dialog.component';

@Component({
    selector: 'form-widget-company',
    templateUrl: './form-widget-company.component.html'
})
export class FormWidgetCompanyComponent implements OnInit, OnDestroy {

    private _unsubscribeAll = new Subject<any>();

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    headline = 'Organisation wählen <small>oder</small> neu erfassen';

    @Input()
    inputLabel = 'Nach Organisation suchen...';

    @Input()
    company$: Observable<Company>;

    @Input()
    optionalFields: boolean;

    @Input()
    slimmedView = false;

    @Input()
    canCreate = true;

    @Input()
    companyId: number;

    @Input()
    editable = false;

    @Input()
    disabled = false;

    @Output()
    companyChanged: EventEmitter<Company> = new EventEmitter<Company>();

    editMode = false;

    companyFormGroup: UntypedFormGroup;

    company: Company;

    loadingCompanies: boolean;

    companies: Company[];

    filteredCompanies$: Observable<Company[]>;

    isSaving = false;

    constructor(private _formBuilder: UntypedFormBuilder,
                private formGroupDirective: FormGroupDirective,
                private _companyApiService: CompanyApiService,
                private _dialog: MatDialog) {
    }

    ngOnInit(): void {

        if (!this.canCreate) {
            this.headline = 'Organisation wählen';
        }

        this._createForm();
        this._loadCompanies();

        if (this.formGroupDirective?.form) {
            this.formGroupDirective.form.addControl('companyForm', this.companyFormGroup);
        }

        if (isFunction(this.company$?.subscribe)) {
            this.company$
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    distinctUntilChanged((previous, current) => previous?.id === current?.id)
                )
                .subscribe(company => {
                    this.companyFormGroup.patchValue({company, ...company}, {onlySelf: true});

                    if (company?.phones && company.phones.length) {
                        this.companyFormGroup.get('phone').patchValue(company.phones[0], {onlySelf: true});
                    }

                    this.companyFormGroup.markAsDirty();
                });
        }
    }

    ngOnDestroy(): void {
        if (this.formGroupDirective?.form) {
            this.formGroupDirective.form.removeControl('companyForm');
        }
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickRemoveCompany(): void {
        this.company = null;
        this.companyFormGroup.get('company').setValue(null);
        this.companyFormGroup.reset();
        this.companyFormGroup.enable();
    }

    openEditDialog(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const disabled = this.companyFormGroup.get('company').disabled;

        this._dialog.open(CompanyEditorDialogComponent, {
            data: {
                parentFormGroup: this.formGroupDirective.form,
                companyFormGroup: this.companyFormGroup,
                company: this.company,
                optionalFields: this.optionalFields,
                appearance: this.appearance
            },
            disableClose: true,
        }).afterClosed().subscribe((company: Company) => {
            if (company) {
                if (company.id === this.company?.id) {
                    this.companyFormGroup.markAsPristine();
                    this.companyFormGroup.markAsUntouched();
                }
                this.company = company;
            }
            if (disabled) {
                this.companyFormGroup.get('company').disable();
            }
        });
    }

    public displayCompanyWith(company: Company): string {
        return company ? company.toDropdownString() : '';
    }

    private _createForm(): void {
        this.companyFormGroup = this._formBuilder.group({
            company: [null],
            name: [null, [U2bValidators.required('Bitte Namen der Organisation angeben')]],
            form: [null],
            street: [
                null,
                this.optionalFields ? [] : [U2bValidators.required('Bitte Strasse angeben')]
            ],
            postCode: [
                null,
                this.optionalFields ? [] : [U2bValidators.required('Bitte PLZ angeben')]
            ],
            city: [
                null,
                this.optionalFields ? [] : [U2bValidators.required('Bitte Stadt angeben')]
            ],
            mail: [null, this.optionalFields ? [] : [U2bValidators.email('Bitte überprüfe die eingegebene E-Mail-Adresse. Beachte das E-Mail Format', true)]],
            phone: this._formBuilder.group({
                type: [null],
                number: [null],
            }),
            note: [null, [U2bValidators.maxLength(1024)]]
        });

        this.companyFormGroup
            .get('company')
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                distinctUntilChanged((previous, current) => previous?.id === current?.id)
            )
            .subscribe((company) => {
                this.company = company;
                this.companyChanged.emit(company);

                if (company && company.id) {
                    disableControlsByName(
                        this.companyFormGroup,
                        ['name', 'form', 'street', 'postCode', 'city', 'mail', 'phone', 'note']
                    );
                } else {
                    enableControlsByName(
                        this.companyFormGroup,
                        ['name', 'form', 'street', 'postCode', 'city', 'mail', 'phone', 'note'],
                        true
                    );
                }
            });

        if (this.disabled) {
            this.companyFormGroup.disable();
        }

    }

    private _loadCompanies(): void {
        this.companies = [];
        this.loadingCompanies = true;
        this._companyApiService
            .getAll(new HttpParams().set('includeBoats', 'true'))
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(() => this.loadingCompanies = false)
            )
            .subscribe(companies => {
                this.companies = companies;
                if (this.companyId) {
                    this.company = companies.find(company => company.id === this.companyId);
                    this.companyFormGroup.patchValue({company: this.company});
                }
            });

        this.filteredCompanies$ = this.companyFormGroup.get('company').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterCompanies(value) : this.companies)
            );
    }

    private _filterCompanies(filter: string): Company[] {
        filter = filter.toLowerCase();
        return this.companies.filter(company => {
            return (company || '').toString().toLowerCase().includes(filter)
                || (company.identNumber || '').toLowerCase().includes(filter)
                || (company.mail || '').toLowerCase().includes(filter)
                || (company.phone || '').toLowerCase().includes(filter);
        });
    }

}
