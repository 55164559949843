import { ElectricMeter, IElectricMeter } from '@shared/models/electric-meter';
import { Boat, IBoat } from '@shared/models/boat';
import { IReadingRaw, Reading } from './electric-meter-reading';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { ElectricMeterPaymentType } from '@modules/bcm/@shared/enums/electric-meter-payment-type';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { v4 as uuidv4 } from 'uuid';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { IProduct, Product } from '@shared/models/product';

export interface IElectricMeterAssignment {
    id?: number;
    from: string;
    to?: string;
    meterReadingStart: number;
    meterReadingEnd?: number;
    electricMeter?: IElectricMeter;
    boat?: IBoat;
    reading?: IReadingRaw;
    readings?: IReadingRaw[];
    paymentType: ElectricMeterPaymentType;
    positions?: InvoicePositionDto[];
    berthAssignment?: IBerthBoatAssignment;
    uuid?: string;
    deleted?: boolean;

    quantity?: number; // helper property
    product?: IProduct; // helper property
}

export class ElectricMeterAssignment {
    id?: number;
    from: Date;
    to?: Date;
    meterReadingStart: number;
    meterReadingEnd?: number;
    electricMeter?: ElectricMeter;
    boat?: Boat;
    reading?: Reading;
    readings?: Reading[];
    paymentType: ElectricMeterPaymentType;
    positions?: InvoicePosition[];
    berthAssignment?: BerthBoatAssignment;
    uuid: string;
    deleted?: boolean;

    quantity?: number; // helper property
    product?: Product; // helper property

    constructor(data = {} as IElectricMeterAssignment) {
        this.id = data.id;
        this.from = tryParseDate(data.from);
        this.to = tryParseDate(data.to);
        this.meterReadingStart = data.meterReadingStart || Math.min(...data.readings?.map(reading => reading.startMeterReading) || [data.reading?.startMeterReading]);
        this.meterReadingEnd = data.meterReadingEnd || Math.max(...data.readings?.map(reading => reading.endMeterReading) || [data.reading?.endMeterReading]);
        this.electricMeter = data.electricMeter ? new ElectricMeter(data.electricMeter) : null;
        this.boat = data.boat ? new Boat(data.boat) : null;
        this.reading = data.reading ? new Reading(data.reading) : null;
        this.readings = (data.readings || []).map(reading => new Reading(reading));
        this.paymentType = data.paymentType;
        this.positions = (data.positions || []).map(position => new InvoicePosition(position));
        this.berthAssignment = data.berthAssignment ? new BerthBoatAssignment(data.berthAssignment) : null;
        this.uuid = data.uuid || uuidv4();
        this.deleted = data.deleted;

        this.quantity = data.quantity;
        this.product = data.product ? new Product(data.product) : null;
    }
}
