import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmInvoicePositionsService } from '@bcmServices/invoice-positions.service';
import { Product } from '@shared/models/product';
import { cloneDeep } from '@shared/functions/clone-deep';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProduktQuantityButton } from '@shared/models/product-quantity-button';
import { Berth } from '@shared/models/berth';
import { Boat } from '@shared/models/boat';
import { BcmPaymentType, Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { TenantRelation } from '@shared/models/tenant-relation';
import { TranslationService } from '@core/translation/translation.service';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { GetProductSubscriptionDatesDialogComponent } from '@sharedComponents/dialogs/get-product-subscription-dates-dialog/get-product-subscription-dates-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProductSubscription } from '@shared/models/product-subscription';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { waitUntil } from '@core/functions/wait-until';
import { BcmCostCenter } from '@shared/models/bcm-cost-center';

@Component({
    selector: 'form-widget-invoice',
    templateUrl: './form-widget-invoice.component.html',
    styleUrls: ['./form-widget-invoice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWidgetInvoiceComponent implements OnInit, OnDestroy, OnChanges {

    invoicePaymentType = BcmPaymentType;

    private _unsubscribeAll = new Subject<any>();

    private _prevGivenInvoicePositions: InvoicePosition[];

    @Input() appearance: MatFormFieldAppearance = 'fill';

    @Input()
    headline = TranslationService.translate('invoicePositions');

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    givenInvoicePositions: InvoicePosition[];

    @Input()
    readonly = false;

    @Input()
    showAddBerthPositionButton = false;

    @Input()
    showInvoiceCreationCheckBox = false;

    @Input()
    givenBerth?: Berth;

    @Input()
    givenBoat?: Boat;

    @Input()
    givenTenantRelation?: TenantRelation;

    @Input()
    givenStartDate: Date;

    @Input()
    givenEndDate: Date;

    @Input()
    berthAssignments?: BerthBoatAssignment[];

    @Input()
    givenCostCenter?: BcmCostCenter;

    person: Person;
    company: Company;
    hasPersonOrCompanyData = false;

    invoiceFormGroup: UntypedFormGroup;

    invoicePositions: InvoicePosition[] = [];

    positionsSum: number;

    colspan = 8;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _bcmInvoicePositionsService: BcmInvoicePositionsService,
                private _matDialog: MatDialog,
                private readonly cdRef: ChangeDetectorRef) {

        this.invoiceFormGroup = this._formBuilder.group({
            positions: [],
            createInvoice: [false],
        });
    }

    ngOnInit(): void {

        const formGroupValues = this.parentFormGroup.getRawValue();

        if (formGroupValues.deviatingInvoiceRecipient) {
            this.person = (formGroupValues.personForm?.person || formGroupValues.personForm || null);
            this.company = (formGroupValues.companyForm?.company || formGroupValues.companyForm || null);
        } else {
            this.person = formGroupValues.ownerPerson || formGroupValues.personForm?.person || formGroupValues.personForm || null;
            this.company = formGroupValues.ownerCompany || formGroupValues.companyForm?.company || formGroupValues.companyForm || null;
        }

        this.hasPersonOrCompanyData = !!this.person || !!this.company;

        this.parentFormGroup.addControl('invoiceForm', this.invoiceFormGroup);

        // this.parentFormGroup.get('invoiceForm')
        //     .get('positions')
        //     .valueChanges
        //     .pipe(
        //         takeUntil(this._unsubscribeAll),
        //     ).subscribe((positions: InvoicePosition[]) => {
        //     this.invoicePositions = positions;
        //     this.updateSum();
        // });

        this.parentFormGroup
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(value => {

                if (value.deviatingInvoiceRecipient) {
                    this.person = (value.personForm?.person || null);
                    this.company = (value.companyForm?.company || null);
                } else {
                    this.person = value.ownerPerson || value.personForm?.person || value.personForm || null;
                    this.company = value.ownerCompany || value.companyForm?.company || value.companyForm || null;
                }

                this.hasPersonOrCompanyData = !!this.person || !!this.company;

                this.cdRef.detectChanges();
            });

        waitUntil(() => this.parentFormGroup.get('invoiceForm')?.value?.positions).then(() => {

            this.parentFormGroup.get('invoiceForm').get('positions').valueChanges
                .subscribe((formPositions: InvoicePosition[]) => {
                    // const positionsToAdd = [];
                    for (const formPosition of formPositions.filter(p => !p.id)) {
                        const index = this.invoicePositions.findIndex(position => position.uuid === formPosition.uuid);
                        if (index !== -1) {
                            this.invoicePositions[index] = formPosition;
                            // } else {
                            //     positionsToAdd.push(formPosition);
                        }
                    }

                    // this.invoicePositions.push(...positionsToAdd);

                    this.invoicePositions = this.invoicePositions
                        .sort((a, b) => {
                            if (a.id && b.id) {
                                return a.id - b.id; // Sort by ID if both have IDs
                            } else if (a.id) {
                                return -1; // a comes before b if a has an ID and b does not
                            } else if (b.id) {
                                return 1; // b comes before a if b has an ID and a does not
                            } else {
                                return new Date(a.insertedOn).getTime() - new Date(b.insertedOn).getTime(); // Sort by insertedOn if both do not have IDs
                            }
                        });

                    this.updateSum(true);

                    this.parentFormGroup.markAsDirty();
                    this.cdRef.detectChanges();
                });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.invoiceFormGroup) {
            return;
        }
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'givenInvoicePositions': {

                        /**
                         * Nach Absprache mit Sandra am 22.03.2023:
                         * 1. Wir löschen an dieser Stelle alle prev Produkte. Diese können wir nur identifizieren,
                         *    indem wir eine uuid einführen, die wir nutzen können, wenn keine ID vorhanden ist.
                         * 2. Wir fügen die neuen Positionen hinzu.
                         * 3. Die manuell hinzugefügten Positionen bleiben so erhalten.
                         * 4. Sollte ein Produkt doppelt vorkommen, dann leben wir erst einmal damit.
                         *    Beispiel:
                         *     User fügt Produkt A mit Menge 10 hinzu. Über die Beziehung kommt nochmals das ProduktA
                         *     mit Menge 1 dazu. In dem Fall haben wir 2 Positionen, ein Mal mit 10 und ein Mal
                         *     mit Menge 1.
                         *
                         *     Möglich ist es, diese zusammenzufassen. Das machen wir aber - wenn überhaupt - erst wenn
                         *     es ein Kunde haben möchte.
                         */
                        this.invoicePositions = this.invoicePositions
                            .filter(invoicePosition => {
                                // Position entfernen, wenn sie zuvor über givenInvoicePositions hinzugekommen ist.
                                const foundPositionInPreviousPositions = (this._prevGivenInvoicePositions || [])
                                    .find(prevInvoicePosition => prevInvoicePosition.uuid === invoicePosition.uuid);

                                return foundPositionInPreviousPositions === undefined;
                            });

                        this.invoicePositions.push(...this.givenInvoicePositions);

                        this._prevGivenInvoicePositions = cloneDeep(this.givenInvoicePositions);
                        this.updateSum();

                        this.cdRef.detectChanges();
                    }
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('contractForm');
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    addBerthInvoicePosition(): void {

        const quantityButtons: ProduktQuantityButton[] = [];

        const berth = this.givenBerth;
        const boat = this.givenBoat || this.parentFormGroup?.value?.boatForm?.boat;

        if (berth?.length && berth?.width) {
            const quantity = roundNumber(berth.length * berth.width, RoundNumberFactor.TwoDecimals);
            quantityButtons.push({
                label: `Fläche Liegeplatz: ${quantity} m²`,
                quantity
            });
        }

        if (boat) {
            let quantity = 0;

            if (boat?.sqm) {
                quantity = boat?.sqm;
            } else if (boat.length && boat.width) {
                quantity = boat.length * boat.width;
            }

            quantity = roundNumber(quantity, RoundNumberFactor.TwoDecimals);

            if (quantity) {
                quantityButtons.push({
                    label: `Fläche Boot: ${quantity} m²`,
                    quantity
                });
            }
        }

        this._bcmInvoicePositionsService
            .getInvoicePosition(
                {
                    person: this.person,
                    company: this.company,
                    boat: this.parentFormGroup?.value?.boatForm?.boat,
                    preselection: {
                        'winterStorage': this.parentFormGroup?.value?.winterStorageAssignment?.winterStorage,
                        'boat': this.givenBoat || this.parentFormGroup?.value?.boatForm?.boat,
                        'berth': this.givenBerth,
                        'tenantRelation': this.givenTenantRelation
                    },
                    fromDate: this.givenStartDate,
                    toDate: this.givenEndDate,
                },
                (product: Product) => {
                    return product.category?.uniqueId === 'Liegeplatzgebühr' || product.category?.parentCategory?.uniqueId === 'Liegeplatzgebühr';
                }, quantityButtons,
                this.givenStartDate,
                this.givenEndDate,
                this.berthAssignments,
                this.givenCostCenter
            )
            .pipe(take(1))
            .subscribe(invoicePositions => {
                if (invoicePositions) {
                    this.invoicePositions.push(...invoicePositions);
                    this.updateSum();
                }
            });
    }

    addInvoicePosition(): void {
        this._bcmInvoicePositionsService
            .getInvoicePosition(
                {
                    person: this.person,
                    company: this.company,
                    boat: this.givenBoat || this.parentFormGroup?.value?.boatForm?.boat,
                    preselection: {
                        'winterStorage': this.parentFormGroup?.value?.winterStorageAssignment?.winterStorage,
                        'boat': this.givenBoat || ((this.parentFormGroup?.value?.winterStorageAssignment?.boat)
                            ? this.parentFormGroup?.value?.winterStorageAssignment?.boat : this.parentFormGroup?.value?.boatForm?.boat),
                        'berth': this.givenBerth,
                        'tenantRelation': this.givenTenantRelation
                    },
                    fromDate: this.givenStartDate,
                    toDate: this.givenEndDate,
                },
                this.showAddBerthPositionButton
                    ? (product: Product) => {
                        return product.category?.uniqueId !== 'Liegeplatzgebühr' && product.category?.parentCategory?.uniqueId !== 'Liegeplatzgebühr';
                    }
                    : undefined,
                [],
                this.givenStartDate,
                this.givenEndDate,
                this.berthAssignments,
                this.givenCostCenter
            )
            .subscribe(invoicePositions => {
                if (invoicePositions?.length > 0) {
                    this.invoicePositions.push(...invoicePositions);
                    this.updateSum();
                }
            });
    }

    editInvoicePosition(invoicePosition: InvoicePosition, index: number): void {
        this._bcmInvoicePositionsService
            .editInvoicePosition(invoicePosition, this.berthAssignments)
            .subscribe(positions => {
                if (positions.length > 0) {
                    this.invoicePositions[index] = positions[positions.findIndex(p => p.positionId !== null)];
                    this.invoicePositions.push(...positions.filter(p => p.positionId === null));
                    this.updateSum();
                }
            });
    }

    removeInvoicePosition(index: number): void {
        // https://trello.com/c/85UpeSaY/4389-feature-gr-liegeplan-produkte-l%C3%B6schen-weitere-meldung-l%C3%B6schen-herausnehmen-4389
        this.invoicePositions.splice(index, 1);
        this.updateSum();
    }

    onDrop(event: CdkDragDrop<InvoicePosition[]>): void {
        moveItemInArray(this.invoicePositions, event.previousIndex, event.currentIndex);
        // this.invoicePositions.forEach((invoicePosition, idx) => {
        //     invoicePosition.order = idx + 1; // todo: add order to DB for each invoice position
        // });
    }

    updateSum(onlySum = false): void {
        if (!onlySum) {
            this.invoiceFormGroup.patchValue({
                positions: this.invoicePositions
            });
        }

        this.positionsSum = (this.invoicePositions || [])
            .reduce((accumulator: number, invoicePosition) => {
                return accumulator + invoicePosition.totalPrice;
            }, 0);

        this.cdRef.detectChanges();
    }

    addOrEditSubscription(event: MouseEvent, index: number): void {
        event.stopPropagation();

        if (this.invoicePositions[index].subscription === null) {

            const aboDialogRef = this._matDialog.open(
                GetProductSubscriptionDatesDialogComponent,
                {
                    data: {
                        initialFromDate: this.givenStartDate,
                        initialPayableOption: this.invoicePositions[index].tenantRelation?.payableOption,
                    }
                }
            );

            aboDialogRef.afterClosed().subscribe(data => {
                if (data) {
                    this.invoicePositions[index].subscription = new ProductSubscription(data);
                }
            });

        } else {

            const aboDialogRef = this._matDialog.open(
                GetProductSubscriptionDatesDialogComponent,
                {
                    data: {
                        subscription: this.invoicePositions[index].subscription,
                    }
                }
            );

            aboDialogRef.afterClosed().subscribe(data => {
                if (data) {
                    this.invoicePositions[index].subscription = new ProductSubscription(data);
                } else if (data === false) {
                    this.invoicePositions[index].subscription = null;
                }
            });

        }
    }

    getBerthAssignmentIndex(berthAssignmentUuid: string): number {
        return this.berthAssignments?.findIndex(assignment => assignment.uuid === berthAssignmentUuid);
    }

}
