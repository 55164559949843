import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Boat } from '@shared/models/boat';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BoatApiService } from '@modules/bcm/@shared/services';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { disableControlsByName } from '@shared/functions/form/disable-controls';

@Component({
    selector: 'app-boat-editor-dialog',
    templateUrl: './boat-editor-dialog.component.html',
    styleUrls: ['./boat-editor-dialog.component.scss']
})
export class BoatEditorDialogComponent {

    showOptionalFields = false;
    isSaving: boolean;

    constructor(public dialogRef: MatDialogRef<BoatEditorDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    parentFormGroup: UntypedFormGroup,
                    boatFormGroup: UntypedFormGroup,
                    boat: Boat,
                    optionalFields: boolean,
                    appearance: MatFormFieldAppearance
                },
                private _boatApiService: BoatApiService,
                private _appNotificationService: AppNotificationService) {

        this.data?.boatFormGroup.get('boat').disable({emitEvent: false});

        this.data?.boatFormGroup.patchValue({
            ...this.data?.boat
        }, {emitEvent: false});

        if (!this.data?.boat) {
            this.data?.boatFormGroup.reset(undefined, {emitEvent: false});
        }

        enableControlsByName(
            this.data?.boatFormGroup,
            ['name', 'length', 'width', 'depth', 'note', 'licensePlate'],
            true
        );
    }

    save(): void {

        if (this.data?.boatFormGroup.invalid) {
            this.data?.boatFormGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
        } else {

            this.isSaving = true;

            const boatData = this.data?.boatFormGroup.getRawValue();
            boatData.id = this.data?.boat?.id;

            delete boatData.boat;

            boatData.sqm = roundNumber(boatData.length * boatData.width, RoundNumberFactor.TwoDecimals);

            boatData.owner = this.data?.parentFormGroup?.get('personForm')?.value?.person;
            boatData.ownerCompany = this.data?.parentFormGroup?.get('companyForm')?.value?.company;

            if (this.data?.boat?.id) {
                this._boatApiService.update(boatData, boatData)
                    .subscribe({
                        next: (boat) => this.close(boat, false),
                        error: () => this.isSaving = false
                    });
            } else {
                this._boatApiService.add(boatData)
                    .subscribe({
                        next: (boat) => this.close(boat),
                        error: () => this.isSaving = false
                    });
            }

        }
    }

    close(boat?: Boat, emitEvent = true): void {
        disableControlsByName(
            this.data?.boatFormGroup,
            ['name', 'length', 'width', 'depth', 'note', 'licensePlate'],
        );

        this.isSaving = false;

        this.data?.boatFormGroup.get('boat').enable({emitEvent: false});

        if (boat) {
            this.data?.boatFormGroup.patchValue({boat}, {emitEvent});
            this.data?.boatFormGroup.markAsDirty();
        }

        this.dialogRef.close(boat);
    }

}
