<div [formGroup]="personFormGroup" fxLayout="row wrap" fxLayoutGap="12px grid">
    <div fxFlex="100">
        <ng-container *ngIf="headline?.length">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <h2 id="section" [innerHTML]="headline"></h2>
            </div>
        </ng-container>

        <ng-container *ngIf="loadingPersons; else formContent">
            <div style="display: flex; justify-content: center; align-items: center;">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
        </ng-container>

        <ng-template #formContent>

            <ng-container *ngIf="!persons?.length">
                <u2b-message>
                    <header>Keine Person gefunden</header>
                    <p>Es konnten keine Personen im System gefunden werden.</p>
                </u2b-message>
            </ng-container>

            <div fxLayout="row" fxLayoutAlign="start start">
                <ng-container *ngIf="!loadingPersons && persons?.length > 0">
                    <mat-form-field [appearance]="appearance" fxFlex>
                        <mat-icon matPrefix>search</mat-icon>
                        <mat-label>{{ inputLabel }}</mat-label>
                        <input matInput
                               [matAutocomplete]="auto"
                               formControlName="person">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPersonWith">
                            <mat-option *ngFor="let person of filteredPersons$ | async" [value]="person">
                                {{ displayPersonWith(person) }}
                            </mat-option>
                        </mat-autocomplete>
                        <div matSuffix>
                            <ng-container *ngIf="editable && person?.id">
                                <button
                                        mat-icon-button
                                        type="button"
                                        color="primary"
                                        matTooltip="Person bearbeiten"
                                        (click)="openEditDialog($event)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!person?.id && canCreate">
                                <button
                                        mat-icon-button
                                        type="button"
                                        color="primary"
                                        matTooltip="Person erstellen"
                                        [disabled]="personFormGroup.get('person').disabled"
                                        (click)="openEditDialog($event)">
                                    <mat-icon>add_circle_outline</mat-icon>
                                </button>
                            </ng-container>
                            <button
                                    mat-icon-button
                                    type="button"
                                    color="warn"
                                    matTooltip="Ausgewählte Person entfernen"
                                    [disabled]="!person?.id || personFormGroup.get('person').disabled"
                                    (click)="onClickRemovePerson()">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>

                        <mat-error>{{ personFormGroup.get('person').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <ng-container *ngIf="person?.id">
                <ng-container *ngIf="person.activeTenantRelationAssignments.length && !slimmedView">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <strong>Aktive Beziehungen:</strong>
                        <bcm-tenant-relation-colored-label
                                [tenantRelations]="person?.activeTenantRelationAssignments"></bcm-tenant-relation-colored-label>
                    </div>
                </ng-container>
            </ng-container>

        </ng-template>

    </div>

</div>
