import { NgModule } from '@angular/core';
import { FormWidgetPersonComponent } from '@modules/bcm/@work-flows/@form-widgets/person/form-widget-person.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { GetAddressDialogModule } from '@shared/components';
import { HtmlLinksModule } from '@modules/bcm/@shared/components/html-links/html-links.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {
    TenantRelationColoredLabelModule
} from '@modules/bcm/@shared/components/tenant-relation-colored-label/tenant-relation-colored-label.module';
import { PersonEditorDialogComponent } from './person-editor-dialog/person-editor-dialog.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [FormWidgetPersonComponent, PersonEditorDialogComponent],
    imports: [

        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,

        GetAddressDialogModule,
        HtmlLinksModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatTooltipModule,
        TenantRelationColoredLabelModule,
        CdkDrag,
        CdkDragHandle
    ],
    exports: [FormWidgetPersonComponent]
})
export class FormWidgetPersonModule {
    
}
