<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ data?.company?.id ? 'Organisation bearbeiten' : 'Neue Organisation erstellen' }}</span>
            <button data-test="B-Dialog schließen" [disabled]="isSaving" mat-icon-button
                    (click)="close()"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="m-0" fusePerfectScrollbar fxFlex="100">
        <div [formGroup]="data?.companyFormGroup"
             fxLayout="row wrap"
             fxLayoutGap="12px grid">

            <mat-form-field [appearance]=data?.appearance fxFlex="100">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required>
                <mat-error>{{ data?.companyFormGroup.get('name').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <ng-container *ngIf="showOptionalFields">
                <mat-form-field [appearance]=data?.appearance fxFlex="100">
                    <mat-label>Gesellschaftsform</mat-label>
                    <input matInput formControlName="form">
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="!data?.optionalFields || showOptionalFields">
                <mat-form-field [appearance]=data?.appearance fxFlex="100">
                    <mat-label>Straße + Hausnummer</mat-label>
                    <input matInput formControlName="street"  [required]="!data?.optionalFields">
                    <mat-error>{{ data?.companyFormGroup.get('street').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <mat-form-field [appearance]=data?.appearance fxFlex="50">
                    <mat-label>PLZ</mat-label>
                    <input matInput formControlName="postCode"  [required]="!data?.optionalFields">
                    <mat-error>{{ data?.companyFormGroup.get('postCode').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <mat-form-field [appearance]=data?.appearance fxFlex="50">
                    <mat-label>Ort</mat-label>
                    <input matInput formControlName="city"  [required]="!data?.optionalFields">
                    <mat-error>{{ data?.companyFormGroup.get('city').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="showOptionalFields">
                <mat-form-field [appearance]=data?.appearance fxFlex="100">
                    <mat-label>E-Mail</mat-label>
                    <input matInput formControlName="mail" type="email">
                    <mat-error>{{ data?.companyFormGroup.get('mail').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <div formGroupName="phone" fxFlex="100" fxLayout="row wrap" fxLayoutGap="12px grid">
                    <mat-form-field [appearance]=data?.appearance fxFlex="60">
                        <mat-label>Telefonnummer</mat-label>
                        <input matInput formControlName="number">
                        <mat-error>{{ data?.companyFormGroup.get('phone').get('number').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>

                    <mat-form-field [appearance]=data?.appearance fxFlex="40">
                        <mat-label>Typ</mat-label>
                        <mat-select formControlName="type">
                            <mat-option value="landline">Festnetz</mat-option>
                            <mat-option value="mobile">Mobil</mat-option>
                            <mat-option value="fax">Fax</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field [appearance]=data?.appearance fxFlex="100">
                    <mat-label>Bemerkungen / Notizen</mat-label>
                    <textarea matInput formControlName="note" rows="3"></textarea>
                    <mat-error>{{ data?.companyFormGroup.get('note').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="!showOptionalFields">
                <button fxFlex="100" class="p-0" mat-stroked-button color="accent"
                        [disabled]="isSaving"
                        (click)="showOptionalFields = true">
                    <mat-icon>visibility</mat-icon>
                    <span>Optionale Felder anzeigen</span>
                </button>
            </ng-container>

        </div>
    </div>

    <div mat-dialog-actions class="m-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button mat-button
                [disabled]="isSaving"
                (click)="close()">
            <mat-icon>cancel</mat-icon>
            <span>Abbrechen</span>
        </button>

        <button mat-flat-button color="accent"
                [disabled]="isSaving"
                (click)="save()">
            <ng-container *ngIf="isSaving; else notSaving">
                <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
            </ng-container>
            <ng-template #notSaving>
                <mat-icon>save</mat-icon>
                <span>Speichern</span>
            </ng-template>
        </button>

    </div>

</div>
