<div [formGroup]="invoiceFormGroup"
     class="container"
     fxLayout="row wrap"
     fxLayoutGap="grid">

    <ng-container *ngIf="headline">
        <div fxFlex="100">
            <h2 [innerHTML]="headline"></h2>
        </div>
    </ng-container>

    <div fxFlex="100" class="mb-16 ml-16">
        <table class="simple sm">
            <thead>
            <tr>
                <th></th>
                <th></th>
                <th>Beschreibung</th>
                <th style="width: 80px;" class="text-center">Anzahl</th>
                <th class="text-right">Einzelpreis</th>
                <th class="text-right">Rabatt</th>
                <th class="text-right">Summe</th>
                <th class="pr-0"></th>
            </tr>
            </thead>
            <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
            <ng-container *ngIf="!invoicePositions?.length">
                <tr>
                    <td [attr.colspan]="colspan" class="text-center">Noch keine Position erfasst.</td>
                </tr>
            </ng-container>
            <ng-container *ngFor="let invoicePosition of invoicePositions; let i = index">
                <tr cdkDrag>
                    <div *cdkDragPreview>
                        {{ invoicePosition.title }}<br>
                        <small>An gewünschter Position loslassen.</small>
                    </div>
                    <td></td>
                    <!--                    <td>-->
                    <!--                        <div class="position-icon">-->
                    <!--                            <ng-container *ngIf="!readonly">-->
                    <!--                                <button mat-icon-button-->
                    <!--                                        [disabled]="invoicePosition.tenantRelationAssignment"-->
                    <!--                                        class="subscription-button" (click)="addOrEditSubscription($event, i)"-->
                    <!--                                        [class.has-subscription]="invoicePosition.subscription"-->
                    <!--                                        matTooltipPosition="left"-->
                    <!--                                        [matTooltip]="invoicePosition.tenantRelationAssignment ? 'Abo wird über Beziehung festgelegt' : (invoicePosition.subscription ? 'Abo bearbeiten' : 'Abo erstellen')">-->
                    <!--                                    <mat-icon>event_repeat</mat-icon>-->
                    <!--                                </button>-->
                    <!--                            </ng-container>-->
                    <!--                        </div>-->
                    <!--                    </td>-->
                    <td>
                        <div class="position-icon">
                            <ng-container
                                    *ngIf="invoicePosition.berthAssignmentUuid && invoicePosition.electricMeterAssignmentUuid">
                                <mat-icon
                                        [matTooltip]="'Stromverbrauch aus Belegung #' + (getBerthAssignmentIndex(invoicePosition.berthAssignmentUuid) + 1)"
                                        matTooltipPosition="right">
                                    exposure
                                </mat-icon>
                            </ng-container>
                            <ng-container
                                    *ngIf="invoicePosition.berthAssignmentUuid && !invoicePosition.electricMeterAssignmentUuid">
                                <mat-icon
                                        [matTooltip]="'Belegung #' + (getBerthAssignmentIndex(invoicePosition.berthAssignmentUuid) + 1)"
                                        matTooltipPosition="right">
                                    directions_boat
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngIf="invoicePosition.fromTenantRelation || invoicePosition.tenantRelationAssignment">
                                <mat-icon [matTooltip]="'Beziehung - ' + invoicePosition.tenantRelation?.name || invoicePosition.tenantRelationAssignment?.tenantRelation?.name"
                                          matTooltipPosition="right">
                                    timeline
                                </mat-icon>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        {{ invoicePosition.title }}

                        <ng-container *ngIf="invoicePosition.voucher">
                            <br>
                            <small>
                                {{ invoicePosition.voucher.title }}<br>
                                (Verbleibend: {{ invoicePosition.voucherRemainingAmount || 0 }})
                            </small>
                        </ng-container>
                    </td>
                    <td style="width: 80px;" class="text-center">
                        <app-input-number matInput
                                          [disabled]="(!!invoicePosition.tenantRelationAssignment && !!invoicePosition.subscription) || readonly"
                                          [(ngModel)]="invoicePosition.quantity"
                                          [ngModelOptions]="{standalone: true}"
                                          (ngModelChange)="updateSum()"></app-input-number>
                    </td>
                    <td class="text-right">
                        {{ invoicePosition.price | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <td class="text-right">
                        {{ invoicePosition.discountPercentage | number : '1.0-2' }}
                    </td>
                    <td class="text-right">
                        {{ invoicePosition.totalPrice | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <ng-container *ngIf="!readonly">
                        <td class="text-right pr-0" width="80px">
                            <div fxLayout="row" fxLayoutAlign="flex-end center">
                                <button type="button" mat-icon-button color="accent"
                                        [disabled]="!!invoicePosition.voucher || (!!invoicePosition.tenantRelationAssignment && !!invoicePosition.subscription)"
                                        [matTooltip]="('voucher' | translate) + ' Positionen können aus technischen Gründen nur gelöscht werden.'"
                                        [matTooltipDisabled]="!invoicePosition.voucher"
                                        (click)="editInvoicePosition(invoicePosition, i)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button type="button" mat-icon-button color="warn"
                                        [disabled]="(!!invoicePosition.tenantRelationAssignment && !!invoicePosition.subscription)"
                                        (click)="removeInvoicePosition(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>

            <div *cdkDragPlaceholder></div>
            </tbody>
            <tfoot>
            <ng-container *ngIf="positionsSum">
                <tr>
                    <td [attr.colspan]="colspan - 2" class="no-border"></td>
                    <td class="text-right padding sum no-border">
                        {{ positionsSum | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <td></td>
                </tr>
            </ng-container>
            <ng-container *ngIf="showAddBerthPositionButton && !readonly">
                <tr>
                    <td [attr.colspan]="colspan" class="text-center">
                        <button mat-button
                                mat-stroked-button
                                type="button"
                                color="accent"
                                data-test="B-Liegegebühr hinzufügen"
                                style="width: 100%"
                                (click)="addBerthInvoicePosition()">
                            <mat-icon>add</mat-icon>
                            Liegegebühr hinzufügen
                        </button>
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngIf="!readonly">
                <tr>
                    <td [attr.colspan]="colspan" class="text-center"
                        matTooltip="Keine Person / Organisation angegeben"
                        [matTooltipDisabled]="hasPersonOrCompanyData">
                        <button mat-button
                                mat-stroked-button
                                type="button"
                                color="accent"
                                data-test="B-Position hinzufügen"
                                style="width: 100%"
                                [disabled]="!hasPersonOrCompanyData"
                                (click)="addInvoicePosition()">
                            <mat-icon>add</mat-icon>
                            Position hinzufügen
                        </button>
                    </td>
                </tr>
            </ng-container>
            </tfoot>
        </table>
    </div>

    <ng-container *ngIf="showInvoiceCreationCheckBox && !readonly">
        <mat-checkbox class="payment-checkbox" formControlName="createInvoice">
            {{ 'createInvoice' | translate }}
        </mat-checkbox>

        <ng-container *ngIf="!invoiceFormGroup?.get('createInvoice').value">
            <u2b-message>
                {{ 'positionsAreWrittenOnTheCoaster' | translate }}
            </u2b-message>
        </ng-container>
    </ng-container>
</div>
