import { Component, Inject } from '@angular/core';
import { CompaniesFacade } from '@modules/bcm/@core/state-management/companies/companies.facade';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Company } from '@shared/models/company';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { AppNotificationService } from '@core/services/app-notification.service';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { HttpParams } from '@angular/common/http';
import { disableControlsByName } from '@shared/functions/form/disable-controls';

@Component({
    selector: 'app-company-editor-dialog',
    templateUrl: './company-editor-dialog.component.html',
    styleUrls: ['./company-editor-dialog.component.scss']
})
export class CompanyEditorDialogComponent {

    showOptionalFields = false;
    isSaving: boolean;

    constructor(public dialogRef: MatDialogRef<CompanyEditorDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    parentFormGroup: UntypedFormGroup;
                    companyFormGroup: UntypedFormGroup;
                    company: Company;
                    optionalFields: boolean;
                    appearance: MatFormFieldAppearance;
                },
                private _dialogRef: MatDialogRef<CompanyEditorDialogComponent>,
                private _appNotificationService: AppNotificationService,
                private _companiesFacade: CompaniesFacade
    ) {

        this.data?.companyFormGroup.get('company').disable({emitEvent: false});

        const primaryPhone = this.data?.company?.phones?.find(phone => phone.isPrimary) || this.data?.company?.phones?.length > 0 ? this.data?.company.phones[0] : null;
        const primaryMail = this.data?.company?.mails?.find(mail => mail.isPrimary) || this.data?.company?.mails?.length > 0 ? this.data?.company.mails[0] : null;

        this.data?.companyFormGroup.patchValue({
            ...this.data?.company,
            mail: primaryMail.mail,
            phone: primaryPhone
        }, {emitEvent: false});

        if (!this.data?.company) {
            this.data?.companyFormGroup.reset(undefined, {emitEvent: false});
        }

        enableControlsByName(
            this.data?.companyFormGroup,
            ['name', 'form', 'street', 'postCode', 'city', 'mail', 'phone', 'note'],
            true
        );

    }

    save(): void {
        if (this.data?.companyFormGroup.invalid) {
            this.data?.companyFormGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder.');
            return;
        }

        this.isSaving = true;
        const companyData = this.data?.companyFormGroup.getRawValue();

        if (companyData.mail) {
            companyData.mails = [
                {mail: companyData.mail, isPrimary: true},
                ...(companyData.mails?.map((mail: { mail: string, type: string, isPrimary: boolean }) => ({
                    mail: mail.mail,
                    type: mail.type,
                    isPrimary: false
                })) || [])
            ];
        }

        if (companyData.phone?.number) {
            companyData.phones = [
                {number: companyData.phone.number, type: companyData.phone.type, isPrimary: true},
                ...(companyData.phones?.map((phone: { number: string, type: string, isPrimary: boolean }) => ({
                    number: phone.number,
                    type: phone.type,
                    isPrimary: false
                })) || [])
            ];
        }

        // Remove duplicates
        companyData.phones = companyData.phones?.filter((phone: { number: string }, index: number, self: {
            number: string
        }[]) =>
            self.findIndex(t => t.number === phone.number) === index);

        companyData.mails = companyData.mails?.filter((mail: { mail: string }, index: number, self: {
            mail: string
        }[]) =>
            self.findIndex(t => t.mail === mail.mail) === index);

        delete companyData.company;

        if (this.data?.company?.id) {
            this._companiesFacade.update(this.data?.company, companyData, new HttpParams())
                .subscribe({
                    next: (company) => this.close(company, false),
                    error: () => this.isSaving = false
                });
        } else {
            this._companiesFacade.add(companyData, new HttpParams(), false)
                .subscribe({
                    next: (company) => this.close(company),
                    error: () => this.isSaving = false
                });
        }
    }

    close(company?: Company, emitEvent = true): void {
        disableControlsByName(
            this.data?.companyFormGroup,
            ['name', 'form', 'street', 'postCode', 'city', 'mail', 'phone', 'note'],
        );

        this.isSaving = false;

        this.data?.companyFormGroup.get('company').enable({emitEvent: false});

        if (company) {
            this.data?.companyFormGroup.patchValue({company}, {emitEvent});
            this.data?.companyFormGroup.markAsDirty();
        }

        this.dialogRef.close(company);
    }

}
