<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ data?.person?.id ? 'Person bearbeiten' : 'Neue Person erstellen' }}</span>
            <button data-test="B-Dialog schließen" [disabled]="isSaving" mat-icon-button
                    (click)="close()"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="m-0" fusePerfectScrollbar fxFlex="100">
        <div [formGroup]="data?.personFormGroup" fxLayout="row wrap" fxLayoutGap="12px grid">

            <mat-form-field [appearance]="data?.appearance" fxFlex="50">
                <mat-label>Anrede</mat-label>
                <mat-select formControlName="salutation" required>
                    <mat-option [value]="salutation.None"></mat-option>
                    <mat-option [value]="salutation.Male">Herr</mat-option>
                    <mat-option [value]="salutation.Female">Frau</mat-option>
                    <mat-option [value]="salutation.Divers">Divers</mat-option>
                </mat-select>
                <mat-error>{{ data?.personFormGroup.get('salutation').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <ng-container *ngIf="showOptionalFields; else placeHolderDiv">
                <mat-form-field [appearance]="data?.appearance" fxFlex="50">
                    <mat-label>Titel</mat-label>
                    <input matInput formControlName="title">
                    <mat-error>{{ data?.personFormGroup.get('title').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>
            </ng-container>

            <ng-template #placeHolderDiv>
                <div fxFlex="50"></div>
            </ng-template>

            <mat-form-field [appearance]="data?.appearance" fxFlex="50">
                <mat-label>Vorname</mat-label>
                <input matInput formControlName="firstName" required>
                <mat-error>{{ data?.personFormGroup.get('firstName').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="data?.appearance" fxFlex="50">
                <mat-label>Nachname</mat-label>
                <input matInput formControlName="lastName" required>
                <mat-error>{{ data?.personFormGroup.get('lastName').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <ng-container *ngIf="!data?.optionalFields || showOptionalFields">
                <mat-form-field [appearance]="data?.appearance" fxFlex="100">
                    <mat-label>Straße + Hausnummer</mat-label>
                    <input matInput formControlName="street" [required]="!data?.optionalFields">
                    <mat-error>{{ data?.personFormGroup.get('street').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <mat-form-field [appearance]="data?.appearance" fxFlex="50">
                    <mat-label>PLZ</mat-label>
                    <input matInput formControlName="postCode" [required]="!data?.optionalFields">
                    <mat-error>{{ data?.personFormGroup.get('postCode').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <mat-form-field [appearance]="data?.appearance" fxFlex="50">
                    <mat-label>Ort</mat-label>
                    <input matInput formControlName="city" [required]="!data?.optionalFields">
                    <mat-error>{{ data?.personFormGroup.get('city').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="showOptionalFields">
                <mat-form-field [appearance]="data?.appearance" fxFlex="100">
                    <mat-label>E-Mail</mat-label>
                    <input matInput formControlName="mail" type="email">
                    <mat-error>{{ data?.personFormGroup.get('mail').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <div formGroupName="phone" fxFlex="100" fxLayout="row wrap" fxLayoutGap="12px grid">
                    <mat-form-field [appearance]="data?.appearance" fxFlex="60">
                        <mat-label>Telefonnummer</mat-label>
                        <input matInput formControlName="number">
                        <mat-error>{{ data?.personFormGroup.get('phone').get('number').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>

                    <mat-form-field [appearance]="data?.appearance" fxFlex="40">
                        <mat-label>Typ</mat-label>
                        <mat-select formControlName="type">
                            <mat-option value="PRIVATE">Privat</mat-option>
                            <mat-option value="WORK">Arbeit</mat-option>
                            <mat-option value="MOBILE">Mobil</mat-option>
                            <mat-option value="FAX">Fax</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field [appearance]="data?.appearance" fxFlex="100">
                    <mat-label>Bemerkungen / Notizen</mat-label>
                    <textarea matInput formControlName="note" rows="3"></textarea>
                    <mat-error>{{ data?.personFormGroup.get('note').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

            </ng-container>

            <ng-container *ngIf="!showOptionalFields">
                <button fxFlex="100" class="p-0" mat-stroked-button color="accent"
                        [disabled]="isSaving"
                        (click)="showOptionalFields = true">
                    <mat-icon>visibility</mat-icon>
                    <span>Optionale Felder anzeigen</span>
                </button>
            </ng-container>

        </div>
    </div>

    <div mat-dialog-actions class="m-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button mat-button
                [disabled]="isSaving"
                (click)="close()">
            <mat-icon>cancel</mat-icon>
            <span>Abbrechen</span>
        </button>

        <button mat-flat-button color="accent"
                [disabled]="isSaving"
                (click)="save()">
            <ng-container *ngIf="isSaving; else notSaving">
                <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
            </ng-container>
            <ng-template #notSaving>
                <mat-icon>save</mat-icon>
                <span>Speichern</span>
            </ng-template>
        </button>


    </div>

</div>
