// person-editor-dialog.component.ts
import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Person } from '@shared/models/person';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { AppNotificationService } from '@core/services/app-notification.service';
import { PersonsFacade } from '@modules/bcm/@core/state-management/persons/persons.facade';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { HttpParams } from '@angular/common/http';
import { Salutation } from '@shared/models/salutation';

@Component({
    selector: 'app-person-editor-dialog',
    templateUrl: './person-editor-dialog.component.html',
    styleUrls: ['./person-editor-dialog.component.scss']
})
export class PersonEditorDialogComponent {

    showOptionalFields = false;
    isSaving: boolean;
    salutation = Salutation;

    constructor(
        public dialogRef: MatDialogRef<PersonEditorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            parentFormGroup: UntypedFormGroup;
            personFormGroup: UntypedFormGroup;
            person: Person;
            optionalFields: boolean;
            appearance: MatFormFieldAppearance;
        },
        private _appNotificationService: AppNotificationService,
        private _personsFacade: PersonsFacade
    ) {

        this.data?.personFormGroup.get('person').disable({emitEvent: false});

        const primaryPhone = this.data?.person?.phones?.find(phone => phone.isPrimary) ||
        this.data?.person?.phones?.length > 0 ? this.data?.person.phones[0] : null;
        const primaryMail = this.data?.person?.mails?.find(mail => mail.isPrimary) ||
        this.data?.person?.mails?.length > 0 ? this.data?.person.mails[0] : null;

        this.data?.personFormGroup.patchValue({
            ...this.data?.person,
            mail: primaryMail?.mail,
            phone: primaryPhone
        }, {emitEvent: false});

        if (!this.data?.person) {
            this.data?.personFormGroup.reset(undefined, {emitEvent: false});
        }

        enableControlsByName(
            this.data?.personFormGroup,
            ['salutation', 'title', 'firstName', 'lastName', 'street', 'postCode', 'city', 'mail', 'phone', 'note'],
            true
        );
    }

    save(): void {
        if (this.data?.personFormGroup.invalid) {
            this.data?.personFormGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
            return;
        }

        this.isSaving = true;
        const personData = this.data?.personFormGroup.getRawValue();

        if (personData.mail) {
            personData.mails = [
                {mail: personData.mail, isPrimary: true},
                ...(personData.mails?.map((mail: { mail: string, type: string, isPrimary: boolean }) => ({
                    mail: mail.mail,
                    type: mail.type,
                    isPrimary: false
                })) || [])
            ];
        }

        if (personData.phone?.number) {
            personData.phones = [
                {number: personData.phone.number, type: personData.phone.type, isPrimary: true},
                ...(personData.phones?.map((phone: { number: string, type: string, isPrimary: boolean }) => ({
                    number: phone.number,
                    type: phone.type,
                    isPrimary: false
                })) || [])
            ];
        }

        // Remove duplicates
        personData.phones = personData.phones?.filter((phone: { number: string }, index: number, self: {
            number: string
        }[]) =>
            self.findIndex(t => t.number === phone.number) === index);

        personData.mails = personData.mails?.filter((mail: { mail: string }, index: number, self: { mail: string }[]) =>
            self.findIndex(t => t.mail === mail.mail) === index);

        delete personData.person;

        if (this.data?.person?.id) {
            this._personsFacade.update(this.data.person, personData, new HttpParams())
                .subscribe({
                    next: (person) => this.close(person, false),
                    error: () => this.isSaving = false
                });
        } else {
            this._personsFacade.add(personData, new HttpParams(), false)
                .subscribe({
                    next: (person) => this.close(person),
                    error: () => this.isSaving = false
                });
        }
    }

    close(person?: Person, emitEvent = true): void {
        disableControlsByName(
            this.data?.personFormGroup,
            ['salutation', 'title', 'firstName', 'lastName', 'street', 'postCode', 'city', 'mail', 'phone', 'note']
        );

        this.isSaving = false;

        this.data?.personFormGroup.get('person').enable({emitEvent: false});

        if (person) {
            this.data?.personFormGroup.patchValue({person}, {emitEvent});
            this.data?.personFormGroup.markAsDirty();
        }

        this.dialogRef.close(person);
    }
}
