import { Boat, IBoat } from '@shared/models/boat';
import { Berth, IBerth } from '@shared/models/berth';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { BookingItemType } from '@modules/bcm/@shared/enums/berth-item-type';
import { ClockService } from '@core/services/clock-service';
import { Company, ICompany } from '@shared/models/company';
import { IPerson, Person } from '@shared/models/person';
import { toDateTimeStringDE } from '@core/functions/to-date-string';
import { BcmBooking, BcmBookingStatus, IBcmBooking } from '@shared/models/bcm-booking';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { ElectricMeterAssignment, IElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { v4 as uuidv4 } from 'uuid';

export interface IBerthBoatAssignment {
    id?: number;
    cancelledOn?: string;
    from: string;
    to: string;
    reservedUntil?: string;
    reservationText?: string;
    note?: string;
    boolean?: boolean;
    type?: BookingType;
    boat: IBoat;
    berth: IBerth;
    arrivalDone?: number;
    departureDone?: number;
    sortIndex?: number;

    absences?: { from: string, until: string }[];

    electricMeterAssignments: IElectricMeterAssignment[];

    booking?: IBcmBooking;

    metaData?: {
        xLabel: 'x' | '/' | '\\' | 'o',
        tooltip: string,
        cssStyles: {
            background: string,
            'box-shadow': string,
            color: string
        },
    };

    reservationEndsToday?: boolean;
    reservationEndsWithinNext7Days?: boolean;

    uuid?: string;

    deleted?: boolean;
}

export class BerthBoatAssignment {

    get sortDate(): Date | null {
        return this.itemType === BookingItemType.Arrival
            ? this.from || null
            : this.to || null;
    }

    constructor(assignment: IBerthBoatAssignment) {

        if (assignment) {
            this.id = assignment.id;

            this.uuid = assignment.uuid || uuidv4();

            this.cancelledOn = tryParseDate(assignment.cancelledOn);
            this.reservedUntil = tryParseDate(assignment.reservedUntil);
            this.reservationText = assignment.reservationText;
            this.type = assignment.type || BookingType.Booking;
            this.arrivalDone = assignment.arrivalDone === 1;
            this.departureDone = assignment.departureDone === 1;

            this.from = tryParseDate(assignment.from);
            this.to = tryParseDate(assignment.to);
            this.note = assignment.note;

            this.electricMeterAssignments = (assignment.electricMeterAssignments || [])
                .map(_ => new ElectricMeterAssignment(_));

            this.booking = assignment.booking ? new BcmBooking(assignment.booking) : null;

            this.active = this.from <= new Date() && (
                this.to == null || this.to >= new Date()
            );

            this.future = this.from > new Date();

            this.itemType = !this.arrivalDone
                ? BookingItemType.Arrival
                : (!this.arrivalDone ? BookingItemType.Departure : BookingItemType.Any);

            if (assignment.metaData) {
                this.metaData = assignment.metaData;
            }

            this.sortIndex = assignment.sortIndex || 1;

            ClockService.time.subscribe(now => {
                this.overdue = this.itemType === BookingItemType.Arrival && this.from < now
                    || this.itemType === BookingItemType.Departure && this.to < now;

                this.active = this.from <= now && (
                    this.to == null || this.to >= now
                );
                this.future = this.from > now;
            });

            this.tooltip = [
                this.type === BookingType.Reservation
                    ? 'Reservierung'
                    : 'Buchung',
                ': ',
                toDateTimeStringDE(this.from),
                this.to
                    ? (' bis ' + toDateTimeStringDE(assignment?.to))
                    : '',
            ].join('');

            this.reservationEndsToday = assignment.reservationEndsToday || null;
            this.reservationEndsWithinNext7Days = assignment.reservationEndsToday || null;
        }

        this.boat = assignment.boat ? new Boat(assignment.boat) : null;
        this.berth = assignment.berth ? new Berth(assignment.berth) : null;

        this.absences = (assignment.absences || []).map(absence => ({
            from: tryParseDate(absence.from),
            until: tryParseDate(absence.until),
        }));
    }

    get person(): Person {
        return this.booking?.person;
    }

    get company(): Company {
        return this.booking?.company;
    }

    get tenantRelationAssignment(): TenantRelationAssignment {
        return this.booking?.tenantRelationAssignment;
    }

    get status(): BcmBookingStatus {
        return this.booking?.general?.status;
    }

    set status(status: BcmBookingStatus) {
        this.booking.general.status = status;
    }

    id: number;
    cancelledOn?: Date;
    from: Date;
    to: Date;
    reservedUntil: Date;
    reservationText?: string;
    note?: string;
    type?: BookingType;
    boat: Boat;
    berth: Berth;
    active: boolean; // needs to be changed to be valid for multiple options [free, block, reserved, locked]
    future: boolean;
    arrivalDone: boolean;
    departureDone: boolean;

    electricMeterAssignments: ElectricMeterAssignment[];

    absences: { from: Date, until: Date }[];

    booking?: BcmBooking;

    sortIndex?: number;

    metaData?: {
        xLabel: 'x' | '/' | '\\' | 'o',
        tooltip: string,
        cssStyles: {
            background: string,
            'box-shadow': string,
            color: string
        },
    };

    reservationEndsToday: boolean;
    reservationEndsWithinNext7Days: boolean;


    // Front-End only
    uuid: string;
    deleted?: boolean;
    durationOfStay?: number;
    itemType?: BookingItemType;
    overdue?: boolean;
    tooltip: string;

    toStringWithDate(): string {
        return this.berth?.toString() + (this.boat ? ` - ${this.boat.toString()} - ${toDateTimeStringDE(this.from)}` : '');
    }

    toString(): string {
        return this.berth?.toString() + (this.boat ? ` - ${this.boat.toString()}` : '');
    }

}

export interface NewBerthBoatAssignmentRaw {
    success: boolean;
    person?: IPerson;
    company?: ICompany;
    positions: InvoicePositionDto[];
    assignments: IBerthBoatAssignment[];
    booking: IBcmBooking;
    errors: {
        [key: string]: Error;
        createdContract?: Error,
        invoiceSending?: Error,
        invoiceCreation?: Error,
    };
}

export interface NewBerthBoatAssignment {
    success: boolean;
    person?: Person;
    company?: Company;
    positions: InvoicePosition[];
    assignments: BerthBoatAssignment[];
    booking: BcmBooking;
    errors: {
        [key: string]: Error;
        createdContract?: Error,
        invoiceSending?: Error,
        invoiceCreation?: Error,
    };
}
