import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { padLeft } from '@shared/functions';
import { ProductSubscription, ProductSubscriptionDto } from '@shared/models/product-subscription';
import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { BookingItemType } from '@modules/bcm/@shared/enums/berth-item-type';
import { BcmOrder } from '@shared/models/bcm-order';
import { Boat } from '@shared/models/boat';
import { Berth } from '@shared/models/berth';
import { Contract, IContract } from '@shared/models/contract';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';
import { v4 as uuidv4 } from 'uuid';
import { ElectricMeterAssignment, IElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';

export interface IBcmBookingStatus {
    id: string;
    name: string;
}

export class BcmBookingStatus {
    id: string;
    name: string;

    constructor(data: IBcmBookingStatus) {
        this.id = data.id;
        this.name = data.name;
    }
}

export interface IBcmBookingTraveler {
    id: number;
    person?: IPerson;
    name: string;
    email: string;
    phone: string;
    birthDate: Date;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
    uuid?: string;
    deleted?: boolean;
}

export class BcmBookingTraveler {
    id: number;
    person?: Person;
    name: string;
    email?: string;
    phone?: string;
    birthDate?: Date;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
    uuid: string;
    deleted?: boolean;

    constructor(dto: IBcmBookingTraveler) {
        this.id = dto.id;
        this.person = dto.person ? new Person(dto.person) : null;
        this.name = dto.name;
        this.email = dto.email;
        this.phone = dto.phone;
        this.birthDate = dto.birthDate;
        this.insertedOn = dto.insertedOn;
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = dto.lastUpdateOn;
        this.lastUpdateBy = dto.lastUpdateBy;
        this.uuid = dto.uuid || uuidv4();
    }
}

export interface BcmBookingGeneralDataDto {
    bookingType?: BookingType;
    reservationText?: string;
    reservedUntil?: Date;
    note?: string;
    status?: IBcmBookingStatus;
    personOrCompany?: 'person' | 'company';
    isBoatOwner?: boolean;
}

export class BcmBookingGeneralData {
    bookingType?: BookingType;
    reservationText?: string;
    reservedUntil?: Date;
    note?: string;
    status?: BcmBookingStatus;
    personOrCompany?: 'person' | 'company';
    isBoatOwner?: boolean;

    constructor(data: BcmBookingGeneralDataDto) {
        this.bookingType = data.bookingType || BookingType.Booking;
        this.reservationText = data.reservationText || null;
        this.reservedUntil = data.reservedUntil || null;
        this.note = data.note || null;
        this.status = data?.status ? new BcmBookingStatus(data.status) : null;
        this.personOrCompany = data.personOrCompany || null;
        this.isBoatOwner = data.isBoatOwner || false;
    }
}

export enum ParticipantType {
    Person = 'person',
    Company = 'company',
}

export interface IBcmBooking {
    id?: number;

    bookingType?: BookingType;
    reservationText?: string;
    reservedUntil?: Date;
    note?: string;
    status?: IBcmBookingStatus;

    personOrCompany?: ParticipantType;
    isBoatOwner?: boolean;

    person: IPerson;
    company: ICompany;

    travelers?: IBcmBookingTraveler[];

    electricMeterAssignments: IElectricMeterAssignment[];

    berthAssignments?: IBerthBoatAssignment[];
    positions?: InvoicePositionDto[];
    invoices?: IInvoice[];

    personSubscriptions?: ProductSubscriptionDto[];
    companySubscriptions?: ProductSubscriptionDto[];

    tenantRelationAssignment?: TenantRelationAssignmentDto;

    contracts?: IContract[];

    documents?: BcmDocumentDto[];

    from?: Date;
    to?: Date;

    arrivalDone: boolean;
    departureDone: boolean;
    cancelledOn: Date;
    cancellationReason: string;
    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmBooking {

    // values from database
    id: number;

    person: Person;
    company: Company;

    general: BcmBookingGeneralData;

    travelers?: BcmBookingTraveler[];

    electricMeterAssignments: ElectricMeterAssignment[];

    berthAssignments: BerthBoatAssignment[];
    positions: InvoicePosition[];
    invoices: Invoice[];

    subscriptions: ProductSubscription[];

    documents: BcmDocument[];

    tenantRelationAssignment?: TenantRelationAssignment;

    contracts: Contract[];

    arrivalDone: boolean;
    departureDone: boolean;
    cancelledOn: Date;
    cancellationReason: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    // helper
    number: string; // auto generated booking number by id
    positionsSaldo: number;
    itemType?: BookingItemType;
    openOrders?: BcmOrder[];
    from: Date;
    to: Date;
    boat: Boat;
    arrivalBerth: Berth;
    departureBerth: Berth;
    saldo: number;

    constructor(data: IBcmBooking) {
        this.id = data.id;

        this.berthAssignments = (data.berthAssignments || []).map(assignment => new BerthBoatAssignment(assignment));
        this.boat = this.berthAssignments.length > 0 ? this.berthAssignments[0].boat : null;

        this.person = data.person ? new Person(data.person) : null;
        this.company = data.company ? new Company(data.company) : null;

        const personIsOwner = this.boat?.owner && this.boat.owner.id === this.person?.id;
        const companyIsOwner = this.boat?.ownerCompany && this.boat.ownerCompany.id === this.company?.id;

        this.general = new BcmBookingGeneralData({
            bookingType: data.bookingType,
            reservationText: data.reservationText,
            reservedUntil: data.reservedUntil,
            note: data.note,
            status: data.status,
            personOrCompany: data.person ? ParticipantType.Person : ParticipantType.Company,
            isBoatOwner: personIsOwner || companyIsOwner
        });

        this.travelers = (data.travelers || []).map(traveler => new BcmBookingTraveler(traveler));

        this.electricMeterAssignments = (data.electricMeterAssignments || []).map(assignment => new ElectricMeterAssignment(assignment));

        this.positions = (data.positions || []).map(position => new InvoicePosition(position));

        this.positions.forEach(position => {
            if (position.bcm_berth_has_assignments_id) {
                position.berthAssignmentUuid = this.berthAssignments.find(a => a.id === position.bcm_berth_has_assignments_id)?.uuid;
            }
            if (position.bcm_electric_meter_has_readings_id) {
                position.electricMeterAssignmentUuid = this.electricMeterAssignments.find(a => a.id === position.bcm_electric_meter_has_boats_id)?.uuid;
            }
            return position;
        });

        this.invoices = (data.invoices || []).map(invoice => new Invoice(invoice));

        this.subscriptions = [
            ...(data.personSubscriptions || []),
            ...(data.companySubscriptions || [])
        ].map(s => new ProductSubscription(s));

        this.tenantRelationAssignment = data.tenantRelationAssignment ? new TenantRelationAssignment(data.tenantRelationAssignment) : null;

        if (this.tenantRelationAssignment) {
            this.tenantRelationAssignment.captureTenantRelation = true;
        }

        this.contracts = (data.contracts || []).map(contract => new Contract(contract));
        this.documents = (data.documents || []).map(doc => new BcmDocument(doc));

        this.arrivalDone = data.arrivalDone;
        this.departureDone = data.departureDone;
        this.cancelledOn = tryParseDate(data.cancelledOn);
        this.cancellationReason = data.cancellationReason;

        this.insertedOn = tryParseDate(data.insertedOn);
        this.insertedBy = data.insertedBy;
        this.lastUpdateOn = tryParseDate(data.lastUpdateOn);
        this.lastUpdateBy = data.lastUpdateBy;

        // helper
        this.number = padLeft(data.id, '000');

        this.positionsSaldo = this.positions
            .map(p => p.price * p.quantity)
            .reduce((acc, val) => acc + val, 0);

        if (data.from) {
            this.from = tryParseDate(data.from);
        } else {
            this.from = this.berthAssignments.length > 0 ? new Date(Math.min(...this.berthAssignments.map(a => a.from.getTime()))) : new Date();
        }

        if (data.to) {
            this.to = tryParseDate(data.to);
        } else {
            this.to = this.berthAssignments.length > 0 ? new Date(Math.max(...this.berthAssignments.map(a => a.to?.getTime()))) : null;
        }

        this.arrivalBerth = this.berthAssignments.length > 0 ? this.berthAssignments[0].berth : null;

        this.departureBerth = this.berthAssignments.length > 0 ? this.berthAssignments[this.berthAssignments.length - 1].berth : null;

        this.saldo = ((this.person || this.company)?.positions || [])
            .reduce((accumulator: number, position) => {
                return accumulator + position.totalPrice;
            }, 0);
    }

    getMinFrom(): Date {
        return this.berthAssignments.length > 0 ? new Date(Math.min(...this.berthAssignments.map(a => a.from.getTime()))) : new Date();
    }

    getMaxTo(): Date {
        return this.berthAssignments.length > 0 ? new Date(Math.max(...this.berthAssignments.map(a => a.to?.getTime()))) : null;
    }

}
